import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import "./Report.css";

const Reports = () => {
  const [hoverInfo, setHoverInfo] = useState(null);
  const [paymentLinkChart, setPaymentLinkChart] = useState({});
  const [revenueChart, setRevenueChart] = useState({});
  const [cryptoMarginsChart, setCryptoMarginsChart] = useState({});
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);

  const handleMouseEnter = (info) => {
    setHoverInfo(info);
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const fetchRevenueChart = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/payment-link/admin-fee-sum`);
      console.log("response", response);
      if (response?.data) {
        const rawData = response?.data || []
        setRevenueChart(rawData);
      }
    } catch (error) {
      console.error("Error fetching revenue", error);
    }
  };

  const fetchPaymentLinkCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/count`
      );

      const rawData = response?.data?.monthlyCounts || [];
      const tokenCounts = {};

      // Aggregate total counts for each token
      rawData.forEach(item => {
        const { code, month } = item._id;
        const count = item.count;
        if (!tokenCounts[code]) {
          tokenCounts[code] = 0;
        }
        tokenCounts[code] += count;
      });

      // Get the top 5 tokens by count
      const top5Tokens = Object.keys(tokenCounts)
        .sort((a, b) => tokenCounts[b] - tokenCounts[a])
        .slice(0, 5);

      const tokensData = {};

      // Process raw data to structure it for Chart.js
      rawData.forEach(item => {
        const { code, month } = item._id;
        const count = item.count;
        const monthName = monthNames[month - 1];

        if (top5Tokens.includes(code)) {
          if (!tokensData[code]) {
            tokensData[code] = {};
          }
          tokensData[code][monthName] = count;
        }
      });

      // Fill missing months with 0
      Object.keys(tokensData).forEach(token => {
        monthNames.forEach(month => {
          if (!tokensData[token][month]) {
            tokensData[token][month] = 0;
          }
        });
      });

      setPaymentLinkChart(tokensData);
    } catch (error) {
      console.error("Error fetching payment links:", error);
    }
  };

  const fetchCryptoMargins = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/payment-link/crypto-margins`)
      console.log("responseOfCryptoMargin",response)
      setCryptoMarginsChart(response)
    }
    catch(error){
      console.error("Error fetching payment links:", error);
    }
  }
  
  useEffect(() => {
    fetchPaymentLinkCount();
    fetchRevenueChart();
    fetchCryptoMargins()
    console.log(cryptoMarginsChart)
  }, []);

  useEffect(() => {
    const colors = [
      'rgb(54,162,235)', // ETH
      'rgb(246,99,132)', // BNB
      'rgb(248,159,64)', // USDT.*
      'rgb(251,205,86)',
      'rgb(75,192,192)',
      'rgb(255,99,132)',
      'rgb(153,102,255)',
      'rgb(255,159,64)',
      'rgb(54,162,145)',
      'rgb(104,162,235)',
      'rgb(164,162,235)',
      'rgb(204,162,235)',
      'rgb(144,162,235)'
    ];

    const createDataset = (token, index) => ({
      label: token,
      data: monthNames.map(month => paymentLinkChart[token] ? paymentLinkChart[token][month] : 0),
      borderColor: colors[index % colors.length],
      backgroundColor: 'white',
    });

    const datasets = Object.keys(paymentLinkChart).map(createDataset);

    const data = {
      labels: monthNames,
      datasets: datasets
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Payment Links'
          }
        }
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef1);
    chartRef1.current = new Chart(document.getElementById("lineChart"), config);
  }, [paymentLinkChart]);


  useEffect(() => {

    const colors = [
      'rgb(54,162,235)', // Blue
      'rgb(246,99,132)'  // Red
    ];
    const netIncomeData = Object.keys(monthNames).map(month => revenueChart[month]?.totalAdminFee || 0);
    const grossIncomeData = Object.keys(monthNames).map(month => revenueChart[month]?.totalAmount || 0);

    const data = {
      labels: monthNames,
      datasets: [
        {
          label: 'Net Income',
          data: netIncomeData,
          borderColor: colors[1],
          backgroundColor: 'rgb(246,99,132)',
          stack: 'combined',
          type: 'bar'
        },
        {
          label: 'Gross Income',
          data: grossIncomeData,
          borderColor: colors[0],
          backgroundColor: 'rgb(54,162,235)',
          stack: 'combined'
        }
      ]
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Revenue Report'
          }
        },
        scales: {
          y: {
            stacked: true
          }
        }
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef2);
    chartRef2.current = new Chart(document.getElementById("stacked-bar"), config);
  }, [revenueChart]);

  useEffect(()=> {

    const colors = [
      'rgb(54,162,235)', // ETH
      'rgb(246,99,132)', // BNB
      'rgb(248,159,64)', // USDT.*
      'rgb(251,205,86)',
      'rgb(75,192,192)',
      'rgb(255,99,132)',
      'rgb(153,102,255)',
      'rgb(255,159,64)',
      'rgb(54,162,145)',
      'rgb(104,162,235)',
      'rgb(164,162,235)',
      'rgb(204,162,235)'
    ]

    // const 

    const data = {
      labels: monthNames,
      datasets: [
        {
          label: 'Dataset 1',
          data: [10,40,40,20,10],
          backgroundColor: 'rgb(54,162,235)',
        }
      ]
    };

    const config = {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Crypto Margins'
          }
        }
      },
    };
    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef3);
    chartRef3.current = new Chart(document.getElementById("doughnutChart"), config);
  },[])

  return (
    <div>
      <div className="content-header">
        <section className="content">
          <div className="card-footer box-container">
            <div className="row">
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">25</h5>
                  <span className="description-text">Total Wallet Users</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">20</h5>
                  <span className="description-text">Total Merchants</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block border-right">
                  <h5 className="description-header">12</h5>
                  <span className="description-text">Active Payment Links</span>
                </div>
              </div>
              <div className="col-sm-3 col-6">
                <div className="description-block">
                  <h5 className="description-header">10</h5>
                  <span className="description-text">Active Merchant Apps</span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2 report-container">
              <div></div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className="btn btn-secondary">
                  <input type="radio" name="options" id="option3" autoComplete="off" /> MONTHLY
                </label>
                <label className="btn btn-secondary">
                  <input type="radio" name="options" id="option3" autoComplete="off" /> QUARTERLY
                </label>
                <label className="btn btn-secondary">
                  <input type="radio" name="options" id="option3" autoComplete="off" /> YEARLY
                </label>
              </div>
            </div>
          </div>
          <div className="container-fluid main-diagram">
            <div className="row mb-2 diagram-parent">
              <div className="col-sm-6 chart-parent line-chart">
                <canvas id="lineChart"></canvas>
              </div>
              <div className="col-sm-6 chart-parent stacked-chart">
                <canvas id="stacked-bar"></canvas>
              </div>
            </div>
            <div className="row mb-2 diagram-parent">
              <div className="col-sm-6 chart-parent line-chart doughnut-chart" >
                <canvas id="doughnutChart" ></canvas>
              </div>
              <div className="col-sm-6 chart-parent stacked-chart">
                <canvas id="stacked-bar"></canvas>
              </div>
            </div>
          </div>
          <div className="chart-info">
            <span className="text">{hoverInfo}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reports;
